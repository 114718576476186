<template>
  <div>
    <div class="background-image-section">
      <div class="d-flex justify-content-center background-color-section">
        <div class="login-card ma-auto">
          <div class="card-head row justify-content-center mb-3">
            <!-- <img src="../assets/images/logo2 2.png" class="img-fluid" /> -->
          </div>
          <div id="card" class="pb-2">
            <div id="card-content">
              <div id="card-title">
                <!-- <img src="../assets/images/Group 7.png" class="img-fluid" /> -->
                <h5 class="font-weight-bold">OTP Page</h5>
              </div>

              <v-form @submit.prevent="onSubmit">
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        label="OTP"
                        placeholder="Enter OTP"
                        v-model="data.two_factor_code"
                        type="text"
                      ></v-text-field>
                    </v-col>
                    <v-btn
                      color="success"
                      class="mr-4"
                      type="submit"
                      :loading="loading"
                    >
                      Submit
                    </v-btn>
                    <v-btn
                      @click="resendOTP"
                      color="primary"
                      class="mr-4 float-right"
                    >
                      Resend OTP
                    </v-btn>
                  </v-row>
                </v-container>
              </v-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import util from "@/util/util.js";

export default {
  name: "OTPPage",
  data: function() {
    return { loading: false, data: {} };
  },
  mounted() {
    console.log(this.$store.state.encrypted_email);
    this.data.email = this.$store.state.encrypted_email;
  },
  methods: {
    onSubmit() {
      this.loading = true;
      this.$http({
        url: "two-factor-code/verify",
        method: "post",
        data: this.data,
      }).then((res) => {
        this.loading = false;
        if (res.data.status == 1) {
          util.loginUsingToken(res.data.data.access_token);
          util.notify(1, "Login Successful");
          this.fetchPermissions();
        } else {
          util.notify(0, "OTP do not match");
        }
      });
    },
    fetchPermissions() {
      this.$http({
        url: "/core/role/permissions/user-permissions",
        method: "get",
      }).then((res) => {
        this.$store.commit("setPermissions", res.data.data);
        this.$router.push({ name: "dashboard" });
        // if (this.can("dashboard-permission")) {
        //   this.$router.push({ name: "AdminLayout" });
        // } else {
        //   this.$router.push({ name: "MainIndex" });
        // }
      });
    },
    resendOTP() {
      this.$http({
        url: "two-factor-code/resend",
        method: "post",
        data: {
          email: this.$store.state.encrypted_email,
        },
      }).then((res) => {
        console.log(res);
        if (res.data.status == 1) {
          //   util.loginUsingToken(res.data.data.access_token);
          util.notify(1, res.data.message);
        } else {
          util.notify(0, res.data.message);
        }
      });
    },
  },
};
</script>
<style type="text/css" scoped>
.background-image-section {
  /* background-image: url("../assets/images/login-bg.jpg"); */
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
}
.background-color-section {
  background-color: rgb(251 251 251);
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
}

#forgot-pass {
  color: #00b0f0;
  font-family: "Raleway", sans-serif;
  font-size: 10pt;
  margin-top: 3px;
  text-align: center;
}
.card-head img {
  height: 70px;
}
#card {
  background: #fbfbfb;
  border-radius: 8px;
  box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.65);
  height: auto;
  width: 350px;
}
#card-content {
  padding: 20px 10px;
}
#card-title {
  padding-bottom: 15px;
  /* padding-top: 13px; */
  text-align: center;
}
#signup {
  color: #00b0f0;
  font-family: "Raleway", sans-serif;
  font-size: 10pt;
  margin-top: 10px;
  text-align: center;
}
.term-condition {
  font-size: 10pt;
  text-align: center;
}
#submit-btn {
  background: -webkit-linear-gradient(right, #00b0f0, #00b0f0);
  border: none;
  border-radius: 21px;
  box-shadow: 0px 1px 8px #00b0f0;
  cursor: pointer;
  color: white;
  font-family: "Raleway SemiBold", sans-serif;
  height: 42.3px;
  margin-top: 20px;
  transition: 0.25s;
  width: 100%;
}
#submit-btn:hover {
  box-shadow: 0px 1px 18px #00b0f0;
}
.form {
  align-items: left;
  display: flex;
  flex-direction: column;
}
.form-border {
  background: -webkit-linear-gradient(right, #00b0f0, #00b0f0);
  height: 1px;
  width: 100%;
}
/* for icon insoide input */
.form-outer {
  max-width: 300px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.container {
  width: 90%;
  max-width: 1160px;
  margin: auto;
}
.form-input {
  margin: 10px auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
input {
  border: 2px solid #eee;
  border-radius: 30px;
  padding: 5px 50px 5px 50px;
  font-size: 14px;
  outline: 0;
  display: block;
  width: 100%;
}
.icon {
  position: absolute;
  left: 10px;
  top: 0;
  font-size: 20px;
  color: #00b0f0;
  height: 39px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
